import React from "react";
import { graphql } from 'gatsby';
import Click from "../../templates/ClickTemplate";

export default function ClickPage({ data: { page } }) {
	return <Click page={page} locale="sv" />;
}

export const query = graphql`
	query {
		page: wpPage(slug: { eq: "klick" }) {
			content
			title
            locale {
                locale
            }
			seo {
				metaDesc
				title
			}
		}
	}
`;